import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import LocalizedLink from "./LocalizedLink";
import LanguageDropdown from "./LanguageDropdown";

const NavItem = styled(LocalizedLink)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 1300px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`;

const NavbarLinks = ({ onAnchorLinkClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <LanguageDropdown type="mobile" />
      <NavItem to="/#aboutUs" onAnchorLinkClick={onAnchorLinkClick}>
        {t("navbarAboutUs")}
      </NavItem>
      <NavItem to="/#benefits" onAnchorLinkClick={onAnchorLinkClick}>
        {t("navbarBenefits")}
      </NavItem>
      <NavItem to="/#testimonials" onAnchorLinkClick={onAnchorLinkClick}>
        {t("navbarTestimonials")}
      </NavItem>
      <NavItem to="/#faq" onAnchorLinkClick={onAnchorLinkClick}>
        {t("navbarFaq")}
      </NavItem>
      <NavItem to="/#contact" onAnchorLinkClick={onAnchorLinkClick}>
        {t("navbarContact")}
      </NavItem>
      <LanguageDropdown />
    </>
  );
};

export default NavbarLinks;
