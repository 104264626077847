import React, { Component } from "react";
import { I18nextProvider } from "react-i18next";
import LocaleContext from "../LocaleContext";

import setupI18next from "./setupI18next";

const withI18next = () => Comp => {
  class I18nHOC extends Component {
    constructor(props) {
      super(props);

      this.i18n = setupI18next();
      this.changeLanguage();
    }

    changeLanguage = () => {
      const { pageContext } = this.props;

      this.addResources(pageContext);
      this.i18n.changeLanguage(pageContext.locale);
    };

    formatTranslations = (translations = {}) => {
      const output = {};

      Object.keys(translations).forEach(word => {
        const wordTranslations = translations[word];
        Object.keys(wordTranslations).forEach(language => {
          if (!output[language]) {
            output[language] = {};
          }

          output[language][word] = translations[word][language];
        });
      });

      return output;
    };

    // @see https://www.i18next.com/overview/api#resource-handling
    // `translation` is the default NS we use consistently.
    addResources = pageContext => {
      const { locale: lng } = pageContext;
      if (!this.i18n.hasResourceBundle(lng, "translation")) {
        const translations = this.formatTranslations(
          require("../i18n/translations.json")
        );
        this.i18n.addResourceBundle(lng, "translation", translations[lng]);
      }
    };

    componentDidUpdate(prevProps) {
      if (this.props.pageContext.locale !== prevProps.pageContext.locale) {
        this.changeLanguage();
      }
    }

    render() {
      return (
        <LocaleContext.Provider
          value={{ locale: this.props.pageContext.locale }}
        >
          <I18nextProvider i18n={this.i18n}>
            <Comp {...this.props} />
          </I18nextProvider>
        </LocaleContext.Provider>
      );
    }
  }

  return I18nHOC;
};

export default withI18next;
