import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";

const LogoWrap = styled.div`
  margin: auto 0;
  margin-left: 1rem;
  flex: 0 1 20vh;
`;

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "smart-harvester-text" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 200, pngQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <LogoWrap as={Link} to="/">
      <Img
        style={{ minHeight: `50px`, minWidth: `147px` }}
        fluid={data.file.childImageSharp.fluid}
        alt="logo"
      />
    </LogoWrap>
  );
};

export default Logo;
