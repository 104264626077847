import React from "react";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";

const FLAGS_QUERY = graphql`
  query flags {
    English: file(name: { eq: "united-kingdom" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 28, height: 28) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    Romanian: file(name: { eq: "romania" }, extension: { eq: "png" }) {
      childImageSharp {
        fixed(width: 28, height: 28) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`;

const displayLanguagesMobile = css`
  @media (min-width: 1300px) {
    display: none;
  }
  display: flex;
`;

const displayLanguagesWeb = css`
  @media (max-width: 1300px) {
    display: none;
  }
  display: flex;
`;

const Languages = styled.div`
  ${props =>
    props.type === "mobile" ? displayLanguagesMobile : displayLanguagesWeb}
  flex-direction: row;
  min-width: 65px;
  justify-content: space-between;
  align-items: center;
  line-height: 2;
  cursor: pointer;
`;

function getLanguages(data) {
  const languages = [
    {
      locale: "ro",
      description: "Română",
      img: data.Romanian.childImageSharp.fixed,
      url: "/",
    },
    {
      locale: "en",
      description: "English",
      img: data.English.childImageSharp.fixed,
      url: "/en",
    },
  ];
  return languages;
}

function LanguageDropdown({ type }) {
  const data = useStaticQuery(FLAGS_QUERY);
  const languages = getLanguages(data);

  return (
    <Languages type={type}>
      {languages.map(language => (
        <React.Fragment key={language.locale}>
          <Link to={language.url}>
            <Img fixed={language.img} alt={language.description} />
          </Link>
        </React.Fragment>
      ))}
    </Languages>
  );
}

export default LanguageDropdown;
