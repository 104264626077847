module.exports = {
  en: {
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Benefits of digitization on a plantation`,
    defaultDescription: `Providing farmers with a more efficient management method of the harvesting process on a plantation with the help of a software application and a barcode system`,
  },
  ro: {
    default: true,
    path: `ro`,
    locale: `ro-RO`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `ro`,
    ogLanguage: `ro_RO`,
    defaultTitle: `Beneficiile digitalizării pe o plantație`,
    defaultDescription: `Ajutarea fermierilor în gestiunea eficientă a recoltării producției de pe o plantație prin intermediul unei aplicații software și al unui sistem de coduri de bare`,
  },
};
