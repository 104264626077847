import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Navbar from "./Navbar";
import withI18next from "../i18n/withI18nNext";
import "./layout.css";

const MainLayout = styled.main`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0;
  h1 {
    font-weight: 700;
    font-size: 1.8rem;
    letter-spacing: -0.03rem;
    line-height: 1.6;
    text-align: left;
    margin: 2rem auto;

    @media (max-width: 1366px) {
      font-size: 1.2rem;
    }
  }

  h2 {
    font-weight: 650;
    font-size: 1.3rem;
    letter-spacing: -0.03rem;

    @media (max-width: 1366px) {
      font-size: 1.1rem;
    }
  }

  p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

const Layout = ({ children, pageContext: { locale } }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);

  return (
    <>
      <Navbar />
      <MainLayout>
        <main>{children}</main>
      </MainLayout>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withI18next()(Layout);
