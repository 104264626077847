import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export default () => {
  i18n.use(initReactI18next).init({
    resources: {},
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "ro",
    lng: "ro",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: { useSuspense: false },
  });

  return i18n;
};
